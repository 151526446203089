<template>
  <v-container class="pa-0">
    <v-row
      no-gutters
      justify="space-between"
      align="center"
    >
      <v-col cols="auto">
        <v-btn
          icon
          @click="prev"
        >
          <v-icon>
            arrow_back_ios_new
          </v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-container>
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="6"
            >
              <PatientCard
                v-model="items[firstItemCursor]"
              />
            </v-col>
            <v-col
              v-show="$vuetify.breakpoint.mdAndUp"
              cols="12"
              md="6"
            >
              <PatientCard
                v-model="items[secondItemCursor]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="auto">
        <v-btn
          icon
          @click="next"
        >
          <v-icon>
            arrow_forward_ios
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PatientCard from './PatientCard'
import Api from './api/patientApi'

export default {
  components: {
    PatientCard
  },
  data() {
    return {
      items: [],
      firstItemCursor: 0,
      secondItemCursor: 0
    }
  },
  async created() {
    await this.setup()
  },
  methods: {
    prev() {
      const lastCursor = this.items.length - 1
      const step = this.$vuetify.breakpoint.mdAndUp ? 2 : 1
      this.firstItemCursor -= step

      if (this.firstItemCursor < 0) {
        this.firstItemCursor += (lastCursor + 1)
      }
      this.secondItemCursor = this.firstItemCursor + 1
      if (lastCursor < this.secondItemCursor) {
        this.secondItemCursor -= (lastCursor + 1)
      }
    },
    next() {
      const lastCursor = this.items.length - 1
      const step = this.$vuetify.breakpoint.mdAndUp ? 2 : 1

      this.firstItemCursor += step
      if (lastCursor < this.firstItemCursor) {
        this.firstItemCursor -= (lastCursor + 1)
      }
      this.secondItemCursor = this.firstItemCursor + 1
      if (lastCursor < this.secondItemCursor) {
        this.secondItemCursor -= (lastCursor + 1)
      }
    },
    async setup() {
      this.items = await Api.getLatestPatientItems()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
