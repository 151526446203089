<template>
  <div class="patient-card">
    <v-container class="pa-0">
      <v-row
        no-gutters
        align="center"
        justify="space-around"
        justify-sm="start"
        class="py-4"
      >
        <v-col
          cols="12"
          sm="auto"
          class="pl-6 pr-2 py-4 text-center"
        >
          <img
            :src="require(`./assets/illust_${value.basic_info.gender === 'male' ? 'man' : 'woman'}_old.png`)"
            style="max-width: 80px; min-width: 40px; width: 100%;"
          >
        </v-col>
        <v-col cols="*">
          <v-container class="pa-0">
            <v-row
              justify="center"
              justify-sm="start"
              class="pl-sm-2"
              no-gutters
            >
              <v-col cols="auto">
                <div class="icon-age">
                  {{ value.basic_info.age }}세
                </div>
              </v-col>
              <v-col
                cols="auto"
                class="px-1 px-sm-3"
                style="color: #C4C4C4;"
              >
                |
              </v-col>
              <v-col cols="auto">
                <div class="icon-gender">
                  {{ getGenderText(value.basic_info.gender) }}
                </div>
              </v-col>
              <v-col
                cols="auto"
                class="px-1 px-sm-3"
                style="color: #C4C4C4;"
              >
                |
              </v-col>
              <v-col
                cols="auto"
                class=""
              >
                <div class="icon-weight">
                  {{ value.extra_info.weight }}Kg
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-divider style="color: #E5E5E5;" />
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          class="px-6 pt-4"
        >
          <span class="card-label">지역</span>{{ addressText }}
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-6 pt-4"
        >
          <span
            v-show="$vuetify.breakpoint.smAndUp"
            class="pr-4"
            style="color: #C4C4C4;"
          >|</span>
          <span class="card-label">장소</span>{{ value.extra_info.location_type === 'home' ? '자택' : '병원' }}
        </v-col>
        <v-col
          cols="12"
          class="px-6 pt-4"
        >
          <span class="card-label">상태</span>{{ value.extra_info.medical_information }}
        </v-col>
        <v-col
          cols="12"
          class="px-6 pt-4"
        >
          <span class="card-label">보조</span>{{ value.extra_info.more_information }}
        </v-col>
        <v-col
          cols="12"
          class="px-6 pt-4"
        >
          <span class="card-label">기간</span>{{ value.extra_info.started_at }} ~ {{ value.extra_info.ended_at }}
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        justify="center"
        class="py-6 px-0 px-sm-4"
      >
        <v-col
          cols="4"
          class="px-1 px-sm-3"
        >
          <div class="card-tag text-caption">
            {{ getGenderTextNeed(value.extra_info.want_carer_gender) }}
          </div>
        </v-col>
        <v-col
          cols="4"
          class="px-1 px-sm-3"
        >
          <div class="card-tag text-caption">
            {{ value.extra_info.care_time_info }}
          </div>
        </v-col>
        <v-col
          cols="4"
          class="px-1 px-sm-3"
        >
          <div class="card-tag text-caption tag-ellipsis">
            {{ value.extra_info.daily_payment }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getConvertedAddressText } from '../../models/addressFinder'

export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          basic_info: {
            age: 0,
            gender: '',
            name: ''
          },
          extra_info: {
            weight: 0,
            medical_information: '',
            more_information: '',
            want_carer_gender: '',
            started_at: '',
            ended_at: '',
            daily_payment: '',
            care_time_info: '',
            location_idx: 5,
            location_detail_idx: 81,
            location_type: '',
            location_type_detail_1: ''
          }
        }
      }
    }
  },
  data() {
    return {
      addressText: ''
    }
  },
  watch: {
    value() {
      this.addressText = '-'
      this.getAddressInfo()
    }
  },
  created() {
    this.getAddressInfo()
  },
  methods: {
    async getAddressInfo() {
      this.addressText = await getConvertedAddressText(this.value.extra_info.location_idx, this.value.extra_info.location_detail_idx)
    },
    getGenderText(gender) {
      let text = '무관'
      if (gender === 'male') {
        text = '남성'
      } else if (gender === 'female') {
        text = '여성'
      }
      return text
    },
    getGenderTextNeed(gender) {
      let text = '성별무관'
      if (gender === 'male') {
        text = '남성선호'
      } else if (gender === 'female') {
        text = '여성선호'
      }
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-card {
// width: 260.01px;
min-height: 176.17px;
background: #FFFFFF;
box-shadow: 0px 1.78396px 8.9198px rgba(0, 0, 0, 0.1);
border-radius: 10px;
}
.icon-age {
  background: url('./assets/icon_cake.png') left center no-repeat;
  background-size: 24px;
  padding-left: 28px !important;
  // padding-right: 12px !important;
}
.icon-gender {
  background: url('./assets/icon_gender.png') left center no-repeat;
  background-size: 24px;
  padding-left: 28px !important;
  // padding-right: 12px !important;
}
.icon-weight {
  background: url('./assets/icon_scale.png') left center no-repeat;
  background-size: 24px;
  padding-left: 28px !important;
  // padding-right: 12px !important;
}
.card-label {
  color: #9E9E9E;
  display: inline-block;
  padding-right: 18px;
}
.card-tag {
  background: #ECF5FF;
  border: 0.44599px solid #007AFF;
  box-sizing: border-box;
  border-radius: 22.2995px;
  text-align: center;
  vertical-align: center;
  height: 36px;
  padding-top: 6px;
}
.tag-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 8px;
}
</style>
