import { Api } from '@/libraries/api'

async function getLatestPatientItems() {
  const url = `/patient/latest_items`
  const result = await Api().get(url)
  const { items } = result.data.data
  return items
}

export default {
  getLatestPatientItems
}
